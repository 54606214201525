import type { TLocations } from '@/shared/types/locations';

import { FILTER_MODES } from './constants';
import type { TFilters } from './model/types';

const { ALL, OPEN, FOOD, NEAR_ME, GROUPS } = FILTER_MODES;
export const filterLocations = (
  { Locations }: { Locations: Nullable<TLocations> },
  filters: TFilters,
  searchNearMeQuery: string,
): TLocations => {
  if (!Locations?.length) return [];

  const activeFilter = filters.find((filter) => filter.active);
  if (!activeFilter) return Locations;

  const id = activeFilter.id.startsWith(GROUPS) ? GROUPS : activeFilter.id;

  switch (id) {
    case ALL:
      return Locations;
    case GROUPS:
      return Locations.filter((location) => location.LocationGroups.includes(activeFilter.name));
    case OPEN:
      return Locations.filter((location) => location.IsOpenNow);
    case FOOD:
      return Locations.filter((location) => location.IsFoodOrderingAvailable);
    case NEAR_ME:
      return Locations.filter((location) => {
        const address = location.Address && location.Address.trim().toLowerCase();
        const globalAddress = location.GlobalAddress && location.GlobalAddress.trim().toLowerCase();
        const groupsDelimited =
          location.GroupsDelimited && location.GroupsDelimited.trim().toLowerCase();

        const search = searchNearMeQuery.trim().toLowerCase();
        return (
          (address && address.includes(search)) ||
          (globalAddress && globalAddress.includes(search)) ||
          (groupsDelimited && groupsDelimited.includes(search))
        );
      });
    default:
      return Locations;
  }
};
