import React from 'react';

import { getCampuses } from '@/entities/locations';

import { useAppSelector } from '@/shared/hooks/appRedux';

import LocationCampus from './LocationCampus';

interface IProps {
  html?: string;
}
const LocationCampuses: React.FC<IProps> = () => {
  const campuses = useAppSelector(getCampuses);

  if (!campuses?.length) return null;

  return (
    <div className="cards campuses">
      <ul className="cards-list">
        {campuses.map((campus) => {
          return <LocationCampus key={campus.Id} card={campus} />;
        })}
      </ul>
    </div>
  );
};

export default LocationCampuses;
