import React from 'react';
import { useIntl } from 'react-intl';

import {
  LocationCard,
  getFavoriteList,
  getIsShowHOP,
  getIsShowOpenClosedStatus,
} from '@/entities/locations';

import { useAppSelector } from '@/shared/hooks/appRedux';

interface IProps {
  html?: string;
}
const LocationFavorites: React.FC<IProps> = () => {
  const favoritesCards = useAppSelector(getFavoriteList);
  const isShowStatus = useAppSelector(getIsShowOpenClosedStatus);
  const isShowHOP = useAppSelector(getIsShowHOP);

  const { formatMessage } = useIntl();
  const messages = {
    title: formatMessage({
      id: 'Locations.LocationPage.Favorites.Title',
    }),
  };
  if (!favoritesCards?.length) return null;
  return (
    <div className="r-w-full r-flex r-flex-col r-justify-center r-items-center">
      <h2 className="r-text-2xl">{messages.title}</h2>
      <div className="cards">
        <ul className="cards-list">
          {favoritesCards.map((card) => {
            return (
              <LocationCard
                key={`id-${card.Id}-${card.DisplayName.split(' ').join('_')}`}
                card={card}
                isShowStatus={isShowStatus}
                isShowHOP={isShowHOP}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default LocationFavorites;
