import React, { useEffect } from 'react';

import { LocationCampuses } from '@/widgets/LocationCampuses';
import { LocationCards } from '@/widgets/LocationCards';
import { LocationFavorites } from '@/widgets/LocationFavorites';
import { LocationFilters } from '@/widgets/LocationFilters';

import { getIsCampusMode, loadData } from '@/entities/locations';

import { APP_ELEMENTS } from '@/shared/constants';
import { useAppDispatch, useAppSelector } from '@/shared/hooks/appRedux';
import { getLoading } from '@/shared/services/loading';
import { LoadingBackground } from '@/shared/services/modal';

const LocationsPage: React.FC = () => {
  const loading = useAppSelector(getLoading);
  const dispatch = useAppDispatch<LocationsAppDispatch>();

  const isCampusMode = useAppSelector(getIsCampusMode);

  useEffect(() => {
    dispatch(
      loadData({
        model: window.locationsAndMenus.model,
        filters: window.locationsAndMenus.filters,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isCampusMode ? (
        <LocationCampuses />
      ) : (
        <>
          <LocationFavorites />
          <LocationFilters />
          <LocationCards />
        </>
      )}

      <LoadingBackground loading={loading} appElement={APP_ELEMENTS.LOCATIONS} />
    </>
  );
};

export default LocationsPage;
