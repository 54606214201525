import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { FoodCourtlistIcon } from '@/entities/locations';

interface IProps {
  stores: string[];
  otherLocationsCount: number;
}

const MAX_SYMBOLS = 60;
const MAX_SYMBOLS_FOR_ONE_NAME = MAX_SYMBOLS + 4;
export const LocationFoodCourtList: React.FC<IProps> = ({ stores, otherLocationsCount }) => {
  const [visibleStoreNames, setVisibleStoreNames] = useState({
    output: '',
    count: 0,
    isFinish: false,
  });

  const { formatMessage } = useIntl();
  const messages = {
    more: formatMessage(
      {
        id: 'Locations.LocationPage.BriefCard.Labels.More',
      },
      { 0: visibleStoreNames.count },
    ),
  };

  useEffect(() => {
    const result = stores.reduce(
      ({ output, count, isFinish }, currentStore, index) => {
        if (currentStore.trim() === '' || isFinish) return { output, count: count + 1, isFinish };

        const separator = output ? ', ' : '';
        const newOutput = `${output}${separator}${currentStore}`;

        if (newOutput.length > MAX_SYMBOLS) {
          if (index === 0) {
            let maxSymbols = MAX_SYMBOLS;
            if (index === stores.length - 1 && !otherLocationsCount) {
              maxSymbols = MAX_SYMBOLS_FOR_ONE_NAME;
            }

            return {
              output: newOutput.slice(0, maxSymbols).concat('...'),
              count,
              isFinish: true,
            };
          }
          return {
            output,
            count: count + 1,
            isFinish: true,
          };
        }

        return { output: newOutput, count, isFinish };
      },
      { output: '', count: otherLocationsCount, isFinish: false },
    );

    setVisibleStoreNames(result);
  }, [messages.more, otherLocationsCount, stores]);

  return (
    <div className="r-flex r-gap-2 r-items-start r-max-h-9">
      <FoodCourtlistIcon className="svg r-h-3 r-flex-shrink-0 FoodCourtlistIcon" />
      <div className="r-flex">
        <span>
          {visibleStoreNames.output}
          &nbsp;&nbsp;&nbsp;
          {visibleStoreNames.count > 0 && messages.more}
        </span>
      </div>
    </div>
  );
};
