import React from 'react';

import { MapMarkerIcon } from '@/entities/locations';

interface IProps {
  address: string;
  globalAddress: string | null;
  isShowGlobalAddress: boolean;
}

export const LocationAddress: React.FC<IProps> = ({
  address,
  globalAddress,
  isShowGlobalAddress,
}) => {
  return (
    <div className={`r-flex r-flex-wrap r-items-baseline r-gap-1 `}>
      <div className="r-flex r-flex-initial r-gap-1 r-items-baseline">
        <MapMarkerIcon className="svg MapMarkerIcon" />
        {address}
      </div>
      {isShowGlobalAddress && globalAddress && (
        <span className="r-flex-initial ">{globalAddress}</span>
      )}
    </div>
  );
};
