import React from 'react';
import { useIntl } from 'react-intl';

import { FILTER_MODES, MapMarkerIcon, getFilters, setFilters } from '@/entities/locations';

import { StickyNavigation } from '@/shared/components/StickyNavigation';
import { useAppDispatch, useAppSelector } from '@/shared/hooks/appRedux';

import NearMe from './components/NearMe';

interface IProps {
  close?: () => void;
}
const LocationFilters: React.FC<IProps> = () => {
  const filters = useAppSelector(getFilters);
  const activeFilter = filters.find((filter) => filter.active);
  const { formatMessage } = useIntl();
  const messages = {
    title: formatMessage({
      id: 'Locations.LocationPage.Filters.Title',
    }),
  };
  const dispatch = useAppDispatch<LocationsAppDispatch>();
  const handleFilter = (id: string): void => {
    dispatch(setFilters(id));
  };

  const options = filters.map((filter) => {
    if (filter.id !== FILTER_MODES.NEAR_ME) return filter;

    return { ...filter, icon: <MapMarkerIcon className="svg MapMarkerIcon" /> };
  });

  return (
    <div className="filter-bar">
      <h2 className="r-text-2xl">{messages.title}</h2>
      {!!options.length && (
        <StickyNavigation
          options={options}
          handleClick={handleFilter}
          className="r-justify-center"
        />
      )}
      {activeFilter?.id === FILTER_MODES.NEAR_ME && <NearMe />}
    </div>
  );
};

export default LocationFilters;
