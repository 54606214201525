import React from 'react';
import { useIntl } from 'react-intl';

import {
  ID_LIST_LOCATIONS,
  LocationCard,
  getFoodCourts,
  getIsShowFavorite,
  getIsShowHOP,
  getIsShowOpenClosedStatus,
  getLocationCards,
} from '@/entities/locations';

import { useAppSelector } from '@/shared/hooks/appRedux';

import { FavoriteCard } from './components/FavoriteCard';

const LocationFilters: React.FC = () => {
  const locationCards = useAppSelector(getLocationCards);
  const foodCourts = useAppSelector(getFoodCourts);
  const isShowStatus = useAppSelector(getIsShowOpenClosedStatus);
  const isShowHOP = useAppSelector(getIsShowHOP);
  const isShowFavorite = useAppSelector(getIsShowFavorite);
  const isAnythingToShow = locationCards.length > 0 || (foodCourts && foodCourts.length > 0);

  const { formatMessage } = useIntl();

  const messages = {
    emptyTextWhenFiltering: formatMessage({ id: 'Locations.LocationPage.EmptyTextWhenFiltering' }),
  };

  return (
    <div className="cards">
      <ul className="cards-list" id={ID_LIST_LOCATIONS} aria-live="polite">
        {locationCards.slice(0, 2).map((card) => {
          return (
            <LocationCard
              key={`id-${card.Id}-${card.DisplayName.split(' ').join('_')}`}
              card={card}
              isShowStatus={isShowStatus}
              isShowHOP={isShowHOP}
            />
          );
        })}
        {isAnythingToShow && isShowFavorite && <FavoriteCard />}
        {locationCards.slice(2, locationCards.length).map((card) => {
          return (
            <LocationCard
              key={`id-${card.Id}-${card.DisplayName.split(' ').join('_')}`}
              card={card}
              isShowStatus={isShowStatus}
              isShowHOP={isShowHOP}
            />
          );
        })}
      </ul>
      {foodCourts?.map((foodCourt) => {
        return (
          <div
            className="r-w-full r-flex r-flex-col r-justify-center r-items-center"
            key={`id-${foodCourt.Id}-${foodCourt.DisplayName}`}
          >
            <h2 className="r-text-2xl">{foodCourt.DisplayName}</h2>
            <ul className="cards-list">
              {foodCourt.Childen?.map((card) => {
                return (
                  <LocationCard
                    key={`id-${card.Id}-${card.DisplayName.split(' ').join('_')}`}
                    card={card}
                    isShowStatus={isShowStatus}
                    isShowHOP={isShowHOP}
                  />
                );
              })}
            </ul>
          </div>
        );
      })}

      <p
        className={`${isAnythingToShow ? 'r-hidden' : ''} r-text-center r-w-full`}
        role="alert"
        aria-live="polite"
        dangerouslySetInnerHTML={{
          __html: messages.emptyTextWhenFiltering,
        }}
      />
    </div>
  );
};

export default LocationFilters;
