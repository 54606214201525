export const EVENT_OPEN_MODAL = 'open_locations';
export const MAX_WIDTH_MODAL = '1190px';

export const FILTER_MODES = {
  ALL: 'all',
  OPEN: 'open',
  FOOD: 'food',
  NEAR_ME: 'nearMe',
  GROUPS: 'groups',
};

export const ID_LIST_LOCATIONS = 'listLocations';
