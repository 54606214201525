import type { IModelLocations, TLocations } from '@/shared/types/locations';

import { createSelector } from '@reduxjs/toolkit';

import { FILTER_MODES } from '../constants';
import { filterLocations } from '../utils';
import type { ILocationsSlice } from './types';

const getLocationsSlice = ({ locations }: LocationsRootState): ILocationsSlice => locations;
const getModel = createSelector(getLocationsSlice, ({ model }): IModelLocations => model);

export const getIsShowHOP = createSelector(getModel, (model) => model.IsShowHOP);
export const getIsShowOpenClosedStatus = createSelector(getModel, (model) => {
  return model.IsShowOpenClosedStatus;
});
export const getIsCampusMode = createSelector(getModel, ({ IsCampusMode }) => IsCampusMode);
export const getCampuses = createSelector(getModel, ({ Campuses }) => Campuses);
const getFiltersModel = createSelector(getLocationsSlice, ({ filters }) => filters);
export const getFilters = createSelector(getFiltersModel, (filters) => filters);

export const getIsShowFavorite = createSelector(
  getModel,
  getFiltersModel,
  ({ IsShowFavorite }, filters) => {
    if (!filters.length) return IsShowFavorite;

    const activeFilter = filters.find((filter) => filter.active);
    return IsShowFavorite && activeFilter?.id === FILTER_MODES.ALL;
  },
);
export const getSearchQuery = createSelector(
  getLocationsSlice,
  ({ searchNearMeQuery }) => searchNearMeQuery,
);
export const getFoodCourts = createSelector(
  getModel,
  getFiltersModel,
  getSearchQuery,
  ({ Locations }, filters, searchNearMeQuery) => {
    return Locations?.reduce<TLocations>((acc, card) => {
      if (!(card.IsFoodCourt && card.DisplayFoodCourtAsHeading)) return acc;
      if (!card.Childen?.length) return acc;

      const filterCards = filterLocations({ Locations: card.Childen }, filters, searchNearMeQuery);
      if (filterCards.length) acc.push({ ...card, Childen: filterCards });
      return acc;
    }, []);
  },
);

const getLocations = createSelector(getModel, getFiltersModel, getSearchQuery, filterLocations);
export const getLocationCards = createSelector(
  getLocations,
  getFoodCourts,
  (locations, foodCourts) => {
    const foodCourtsCards = foodCourts?.reduce<TLocations>((acc, foodCourt) => {
      return [...acc, ...foodCourt.Childen!];
    }, []);

    return locations.reduce<TLocations>((acc, card) => {
      const isFromFoodCourt = foodCourtsCards?.some((foodCard) => {
        return card.Id ? card.Id === foodCard.Id : card.DisplayName === foodCard.DisplayName;
      });

      if (!isFromFoodCourt) acc.push(card);
      return acc;
    }, []);
  },
);

export const getFavoriteIds = createSelector(
  getLocationsSlice,
  ({ favoriteIds }): string[] => favoriteIds,
);
export const getFavoriteList = createSelector(
  getModel,
  getFavoriteIds,
  ({ Locations }, favoriteIds) => Locations?.filter(({ Id }) => Id && favoriteIds.includes(Id)),
);
