import React from 'react';

import { Img } from '@/shared/components/Img';

export const FavoriteCard: React.FC = () => {
  return (
    <li>
      <div className="location-card_favorite">
        <Img
          width="100%"
          src={window.locationsAndMenus.favorite.img}
          alt={window.locationsAndMenus.favorite.alt}
        />
      </div>
    </li>
  );
};
