import React, { memo } from 'react';
import { useIntl } from 'react-intl';

import { ButtonAddToFavorites } from '@/shared/components/Button';
import { useAppDispatch, useAppSelector } from '@/shared/hooks/appRedux';
import { ModalService } from '@/shared/services/modal';
import type { ILocation } from '@/shared/types/locations';
import { isPropsEqual } from '@/shared/utils/props-comparison';
import { hasValue } from '@/shared/utils/validation';

import { AddOrRemoveFromFavorite, ClockIcon, getFavoriteIds } from '../../index';
import { AddFavorite } from './blocks/AddFavorite';
import { LocationAddress } from './blocks/LocationAddress';
import { LocationFoodCourtList } from './blocks/LocationFoodCourtList';

interface IProps {
  card: ILocation;
  isShowStatus: boolean;
  isShowHOP: boolean;
}
const LocationCard: React.FC<IProps> = ({ card, isShowStatus, isShowHOP }) => {
  const favoriteIds = useAppSelector(getFavoriteIds);
  const isShowFavoriteIcon = !!card.Id && card.UserFavoritiesEnabled;
  const isFavorite = isShowFavoriteIcon && favoriteIds.includes(card.Id!);

  const dispatch = useAppDispatch<LocationsAppDispatch>();
  const handlerAddOrRemoveFromFavorite = (): void => {
    dispatch(
      AddOrRemoveFromFavorite({ locationId: card.Id!, isRemoving: isFavorite }, (data) => {
        if (!isFavorite && data && data.Unauthorized)
          ModalService.open(AddFavorite, {
            displayName: card.DisplayName,
          });
      }),
    );
  };
  const { formatMessage } = useIntl();
  const messages = {
    timeSVGClock: formatMessage({
      id: 'Locations.LocationPage.Titles.ClockSVGTitle',
    }),
    addressSVGMapMarker: formatMessage({
      id: 'Locations.LocationPage.Titles.MapMarkerSVGTitle',
    }),
    open: formatMessage({ id: 'Locations.LocationPage.BriefCard.Labels.Open' }),
    closed: formatMessage({
      id: 'Locations.LocationPage.BriefCard.Labels.Closed',
    }),
    foodOrdering: formatMessage({
      id: 'Locations.LocationPage.BriefCard.Labels.FoodOrdering',
    }),
    more: formatMessage(
      {
        id: 'Locations.LocationPage.BriefCard.Labels.More',
      },
      { 0: card.OtherLocationsCount },
    ),
  };
  const urlLocationPage = window.location.origin + card.LocationUrl;

  const displayName = hasValue(card.DisplayName) ? card.DisplayName : undefined;

  const showFooter =
    (!card.HideAddress && card.Address) ||
    (card.IsFoodCourt && card.ChildLocationsNames?.length) ||
    isShowFavoriteIcon;
  return (
    <li>
      <div className="location-card">
        <a
          href={urlLocationPage}
          aria-label={displayName}
          className="location-card-link !r-no-underline"
        >
          <div
            className="location-card-header"
            style={{ backgroundImage: `url(${card.LocationImageUrl})` }}
          >
            {!card.HideName && displayName && (
              <span className="location-card-header__display-name">{displayName}</span>
            )}
          </div>
        </a>

        {isShowStatus && (
          <div className="location-card-status">
            <div className="r-uppercase r-font-bold">
              {card.IsOpenNow ? (
                <span className="r-text-green-700">{messages.open}</span>
              ) : (
                <span className="r-text-red-700">{messages.closed}</span>
              )}
            </div>

            {isShowHOP && hasValue(card.HoursOfOperations) && (
              <div className="r-flex r-gap-1">
                <ClockIcon className="svg ClockIcon" />
                <span>{card.HoursOfOperations}</span>
              </div>
            )}
          </div>
        )}

        {showFooter && (
          <div className="location-card-footer">
            <div className="r-flex r-gap-2 r-flex-col r-flex-grow">
              {!card.HideAddress && card.Address && (
                <LocationAddress
                  address={card.Address}
                  globalAddress={card.GlobalAddress}
                  isShowGlobalAddress={!(card.HideCityStateZip || card?.IsUseShortLocationAddress)}
                />
              )}

              {card.IsFoodCourt && card.ChildLocationsNames?.length && (
                <LocationFoodCourtList
                  stores={card.ChildLocationsNames}
                  otherLocationsCount={card.OtherLocationsCount}
                />
              )}
            </div>

            {isShowFavoriteIcon && (
              <div className="r-flex r-items-end">
                <ButtonAddToFavorites
                  id={card.Id}
                  className="!r-mx-0"
                  handlerClick={handlerAddOrRemoveFromFavorite}
                  marketingName={card.DisplayName}
                  IsUserFavorite={isFavorite}
                  data-testid="location-card-footer-button-add-to-favorites"
                />
              </div>
            )}
          </div>
        )}
      </div>
    </li>
  );
};

export default memo(LocationCard, isPropsEqual);
