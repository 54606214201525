import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { ModalOne } from '@/shared/services/modal';
import type { TPortalModalComponent } from '@/shared/services/modal/type';

interface IProps {
  displayName: string;
}
const Content = styled.div`
  margin: 8px 32px 14px;
  font-size: 16px;
  color: #000;
`;

export const AddFavorite: TPortalModalComponent<IProps> = ({ displayName, close }) => {
  const { formatMessage } = useIntl();
  const messages = {
    addToFavoriteModalBody: formatMessage(
      { id: 'Locations.LocationPage.Favorites.Modal.Description' },
      { 0: displayName },
    ),
    skip: formatMessage({ id: 'Locations.LocationPage.Favorites.Modal.Buttons.Skip' }),
    signIn: formatMessage({ id: 'Locations.LocationPage.Favorites.Modal.Buttons.SignIn' }),
    added: formatMessage({ id: 'Locations.LocationPage.Favorites.Modal.Title' }),
  };

  const goToLocationPage = (): void => {
    window.location.assign(`${window.location.origin}/Login`);
  };
  const footer = (
    <>
      <button
        onClick={close}
        type="button"
        className="
        r-bg-primary hover:r-bg-secondary hover:r-text-white r-border-none
        r-m-0 r-px-6 r-py-2 r-text-white r-rounded-[4px] r-h-10"
      >
        <span dangerouslySetInnerHTML={{ __html: messages.skip }} />
      </button>
      <button
        onClick={goToLocationPage}
        type="button"
        className="
      r-bg-primary hover:r-bg-secondary hover:r-text-white r-border-none
      r-m-0 r-px-6 r-py-2 r-text-white r-rounded-[4px] r-h-10"
      >
        <span dangerouslySetInnerHTML={{ __html: messages.signIn }} />
      </button>
    </>
  );
  return (
    <ModalOne
      title={
        <span
          dangerouslySetInnerHTML={{
            __html: messages.added,
          }}
        />
      }
      handlerClose={close}
      footer={footer}
      isFocusOnCloseButton
    >
      <Content
        dangerouslySetInnerHTML={{
          __html: messages.addToFavoriteModalBody,
        }}
      />
    </ModalOne>
  );
};
