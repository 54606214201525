import React from 'react';

import { LocationsPage } from '@/pages/locations';

import { AppProviders } from '@/shared/components/Providers';
import { APP_ELEMENTS } from '@/shared/constants';
import { Modal } from '@/shared/services/modal';
import { Notification } from '@/shared/services/notification';
import { DefaultTheme } from '@/shared/themes';

import store from './store';

const App: React.FC = () => {
  return (
    <AppProviders resources={window.locationsAndMenus.resources} store={store} theme={DefaultTheme}>
      <LocationsPage />
      <Modal appElement={APP_ELEMENTS.LOCATIONS} />
      <Notification />
    </AppProviders>
  );
};

export default App;
