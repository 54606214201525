import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  FILTER_MODES,
  ID_LIST_LOCATIONS,
  getSearchQuery,
  setSearchQuery,
} from '@/entities/locations';

import { useAppDispatch, useAppSelector } from '@/shared/hooks/appRedux';
import { srSpeak } from '@/shared/utils/srSpeak';

import SearchSvg from '../search.svg';

interface IProps {
  close?: () => void;
}
const NearMe: React.FC<IProps> = () => {
  const { formatMessage } = useIntl();
  const messages = {
    inputLabel: formatMessage({
      id: 'Locations.LocationPage.NearMe.Label',
    }),
    inputPlaceholder: formatMessage({
      id: 'Locations.LocationPage.NearMe.Placeholders.Input',
    }),
    getCurrentLocation: formatMessage({
      id: 'Locations.LocationPage.NearMe.Buttons.GetCurrentLocation',
    }),
    locationsIsFiltered: formatMessage({
      id: 'Locations.LocationPage.NearMe.Search.LocationsIsFiltered',
    }),
  };
  const searchQuery = useAppSelector(getSearchQuery);
  const [search, setSearch] = useState(searchQuery);

  const dispatch = useAppDispatch<LocationsAppDispatch>();
  useEffect(() => {
    const delaySearch = setTimeout(() => {
      dispatch(setSearchQuery(search));
    }, 500);

    return () => clearTimeout(delaySearch);
  }, [dispatch, search]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setSearch(value);
    srSpeak(messages.locationsIsFiltered, 'polite');
  };
  return (
    <div className="r-flex r-flex-col r-gap-1 r-w-full md:r-w-96 r-mt-4">
      <div className="r-flex r-gap-1 r-border r-border-solid r-border-gray-500 r-items-center">
        <div className="r-w-full">
          <label htmlFor={`${FILTER_MODES.NEAR_ME}_field`} className="r-sr-only">
            {messages.inputPlaceholder}:
          </label>
          <input
            onChange={handleChange}
            id={`${FILTER_MODES.NEAR_ME}_field`}
            className="r-w-full r-box-border r-border-0
            focus:r-outline-none focus:r-border-primary
            r-py-1 r-px-4"
            placeholder={messages.inputPlaceholder}
            aria-controls={ID_LIST_LOCATIONS}
            type="text"
          />
        </div>
        <SearchSvg
          className="r-stroke-gray-400 r-stroke-[2] r-fill-none r-w-4 r-h-4 r-relative r-right-2"
          role="presentation"
          aria-hidden="true"
          focusable="false"
        />
      </div>
    </div>
  );
};

export default NearMe;
