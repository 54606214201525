import React from 'react';

import type { ICampus } from '@/shared/types/locations';
import { hasValue } from '@/shared/utils/validation';

interface IProps {
  card: ICampus;
}
const LocationCampus: React.FC<IProps> = ({ card }) => {
  const displayName = hasValue(card.DisplayName) ? card.DisplayName : undefined;
  const urlLocationPage = window.location.origin + card.LocationUrl;

  return (
    <li>
      <div className="campus-card">
        <a
          href={urlLocationPage}
          aria-label={displayName}
          className="campus-card-link !r-no-underline"
        >
          <div
            className="campus-card-body"
            style={{
              backgroundImage: `url(${card.LocationImageUrl})`,
            }}
          >
            {!card.HideName && displayName && (
              <span className="campus-card-body__display-name">{displayName}</span>
            )}
          </div>
        </a>
      </div>
    </li>
  );
};

export default LocationCampus;
