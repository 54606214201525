import { addOrRemoveFromLocationFavoriteApi } from '@/shared/api/locations';
import { IApiResponseAddOrRemoveFromLocationFavorite } from '@/shared/api/locations/types';
import { setLoading } from '@/shared/services/loading';
import { NOTIFICATIONS, NotificationService } from '@/shared/services/notification';
import { IModelLocations, TFiltersLocations } from '@/shared/types/locations';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { FILTER_MODES } from '../constants';
import { ILocationsSlice, TActionAddOrRemoveFromLocationFavorite, TFilters } from './types';

const { ALL, OPEN, FOOD, NEAR_ME, GROUPS } = FILTER_MODES;
const mainSlice = createSlice({
  name: 'main',
  initialState: {
    model: {
      IsCampusMode: false,
      IsDisplayFoodCourtAsHeading: false,
      IsShowHOP: false,
      IsShowOpenClosedStatus: false,
      IsShowFavorite: false,
      Locations: [],
      Campuses: [],
    },
    favoriteIds: [],
    filters: [],
    searchNearMeQuery: '',
  } as ILocationsSlice,
  reducers: {
    setModel: (state, action: PayloadAction<IModelLocations>): void => {
      state.model = {
        ...state.model,
        ...action.payload,
      };

      const favoriteIds = action.payload.Locations?.reduce<string[]>((acc, item) => {
        if (item.IsFavorite && item.Id) acc.push(item.Id);
        return acc;
      }, []);

      if (favoriteIds?.length) state.favoriteIds = favoriteIds;
    },
    setFiltersModel: (state, { payload }: PayloadAction<TFiltersLocations>): void => {
      const uniqueGroupsOfLocations = state.model.Locations?.reduce<string[]>((acc, location) => {
        if (location.LocationGroups.length) {
          const groups = location.LocationGroups.filter((loc) => {
            return !acc.includes(loc);
          });
          return [...acc, ...groups];
        }
        return acc;
      }, []);

      const locationGroups = uniqueGroupsOfLocations?.map((groupName) => ({
        id: `${GROUPS}_${groupName.replace(/\s+/g, '')}`,
        name: groupName,
        active: false,
      }));
      const locationFilters = [
        payload.All.IsChecked && {
          id: ALL,
          name: payload.All.Name,
          active: true,
        },
        ...(payload.LocationGroups.IsChecked && locationGroups?.length ? locationGroups : []),
        payload.OpenNow.IsChecked && {
          id: OPEN,
          name: payload.OpenNow.Name,
          active: false,
        },
        payload.NearMe.IsChecked && {
          id: NEAR_ME,
          name: payload.NearMe.Name,
          active: false,
        },
        payload.FoodOrdering.IsChecked && {
          id: FOOD,
          name: payload.FoodOrdering.Name,
          active: false,
        },
      ];

      state.filters = locationFilters.filter(Boolean) as TFilters;
    },
    setFilters: (state, { payload }: PayloadAction<string>): void => {
      if (!state.filters) return;

      state.filters = state.filters.map((filter) => {
        if (filter.id === payload)
          return {
            ...filter,
            active: true,
          };
        return {
          ...filter,
          active: false,
        };
      });
    },
    setFavorite: (state, action: PayloadAction<IApiResponseAddOrRemoveFromLocationFavorite>) => {
      const { UserFavoritesEnabled, FavoriteIds } = action.payload;
      state.favoriteIds = UserFavoritesEnabled ? FavoriteIds : [];
    },
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchNearMeQuery = action.payload.trim();
    },
  },
});

export const { setModel, setFilters, setFiltersModel, setFavorite, setSearchQuery } =
  mainSlice.actions;

export default mainSlice.reducer;

export const loadData =
  (payload: { model: IModelLocations; filters: Nullable<TFiltersLocations> }) =>
  (dispatch: LocationsAppDispatch) => {
    dispatch(setModel(payload.model));
    if (payload?.filters) dispatch(setFiltersModel(payload.filters));
  };

export const AddOrRemoveFromFavorite: TActionAddOrRemoveFromLocationFavorite =
  ({ locationId, isRemoving }, callback) =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await addOrRemoveFromLocationFavoriteApi({ locationId, isRemoving });

      if (response && response) {
        dispatch(setFavorite(response));
        callback?.(response);
      }
    } catch (error) {
      NotificationService.open({
        status: NOTIFICATIONS.ERROR,
        text: (error as Error).message,
      });
    } finally {
      dispatch(setLoading(false));
    }
  };
