import 'core-js';
import React from 'react';
import { createRoot } from 'react-dom/client';
import 'regenerator-runtime/runtime';

import { APP_ELEMENTS } from '@/shared/constants';

import App from './App';
import './styles.scss';

const container = document.getElementById(APP_ELEMENTS.LOCATIONS);
const root = createRoot(container!);
root.render(<App />);
