import { LocationsReducer } from '@/entities/locations';

import { LoadingReducer } from '@/shared/services/loading';

import { Action, ThunkAction, combineReducers, configureStore } from '@reduxjs/toolkit';

const rootReducer = combineReducers({
  locations: LocationsReducer,
  loading: LoadingReducer,
});

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production' && {
    name: 'Locations Redux FSD',
  },
});

export default store;

export type LocationsRootState = ReturnType<typeof store.getState>;
export type LocationsAppDispatch = typeof store.dispatch;
export type LocationsAppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  LocationsRootState,
  unknown,
  Action<string>
>;
